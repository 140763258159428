<template>
  <div class="user-side-info">
    <div class="user-side-info__list">
      <div class="user-side-info__bl">
        <div class="user-side-info__ell">Дата рождения:</div>
        <div class="user-side-info__ell">{{ innerPersone.birthday }}</div>
      </div>
      <div class="user-side-info__bl">
        <div class="user-side-info__ell">Пол:</div>
        <div class="user-side-info__ell">{{ innerPersone.gender }}</div>
      </div>
      <div v-if="innerPersone.city" class="user-side-info__bl">
        <div class="user-side-info__ell">Адрес:</div>
        <div class="user-side-info__ell">{{ innerPersone.city }}</div>
      </div>
      <div class="user-side-info__bl">
        <div class="user-side-info__ell">Телефон:</div>
        <div class="user-side-info__ell">{{ innerPersone.mobile }}</div>
      </div>
      <template v-if="company">
        <div class="user-side-info__bl">
          <div class="user-side-info__ell">Cотрудник компании:</div>
          <div class="user-side-info__ell">{{ company.name }}</div>
        </div>
        <div class="user-side-info__bl">
          <div
            class="user-side-info__ell"
          >
            <a
              href=""
              class="user-side-leave"
              @click.stop.prevent="leaveCompany"
            >Выйти из компании</a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  props: {
    persone: {
      type: Object,
      required: false,
    },
  },

  computed: {
    ...mapState({
      user: state => state.user.user,
      genders: state => state.enums.genders,
    }),
    company() {
      return this.user?.my_company;
    },
    innerPersone() {
      const persone = this.persone ? this.persone : this.user;
      const personeGender = this.genders.find(el => el.key === persone.gender).value;
      return {
        ...persone,
        gender: personeGender,
      };
    },
  },

  methods: {
    ...mapActions('user', ['getUser']),
    leaveCompany() {
      this.$modal.show('Confirm', {
        title: 'Подтвердите, что действительно хотите покинуть компанию'
      }).then(res => {
        if (!res) return;
        RequestManager.Invite.leaveCompany().then(() => {
          this.getUser();
          this.$modal.show('Accept', {
            title: 'Вы покинули компанию'
          });
        });
      });
    }
  }
};
</script>


<style lang="scss" scoped>


// user-side-info
.user-side-info {
  .user-side-leave {
    text-decoration: underline;
    color: #757579;
    letter-spacing: -0.08px;
    font-size: 15px;
    opacity: .9;
  }
  .user-side-info__list {
    margin-bottom: 20px;
    .user-side-info__bl {
      display: flex;

      &:not(:last-child){
        margin-bottom: 12px;
      }
      .user-side-info__ell {
        color: rgba(#020202, 0.9);
        font-size: 13px;
        &:first-child {
          margin-right: 6px;
        }
      }
    }
  }

  @include respond-to('sm') {
    .user-side-info__list {
      margin-bottom: 28px;
      .user-side-info__bl {
        .user-side-info__ell {
          font-size: 15px;
        }
      }
    }
  }
}


</style>
