<template>
  <SideWrapper>
    <SideAvatar :editRoute="{ name: $routeName.ASSISTANCE_NEED_EDIT_PROFILE }" />
    <SideCollapse>
      <SidePersoneAttrs></SidePersoneAttrs>
    </SideCollapse>
    <div class="side-panel__switch">
      <div class="side-panel__label">Нужна помощь</div>

      <v-switch
        @change="switchHandler"
        :input-value="needHelp"
      />

    </div>
    <a @click.prevent="$modal.show('ServiceNeed', { place: 'account' })" href="" class="btn btn--prime">
      Создать новую заявку
    </a>
  </SideWrapper>
</template>

<script>
import SideAvatar from '@/component/Module/SidePanel/SideAvatar.vue';
import SidePersoneAttrs from '@/component/Module/SidePanel/SidePersoneAttrs.vue';
import SideCollapse from '@/component/Module/SidePanel/SideCollapse.vue';
import SideWrapper from '@/component/Module/SidePanel/SideWrapper.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'SidePanel',
  components: {
    SideAvatar,
    SidePersoneAttrs,
    SideCollapse,
    SideWrapper,
  },
  data() {
    return {
      switchBtn: true,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    needHelp() {
      return this.user.need_help;
    }
  },
  methods: {
    ...mapActions('user', ['updateUserProfileData']),
    async switchHandler(v) {
      await this.updateUserProfileData({ user: { need_help: v }});
    }
  }
};
</script>

<style lang="scss" scoped>
.side-panel__switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding: 0 14px;
  border-bottom: 1px solid rgba(#000000, 0.2);
  .side-panel__label {
    font-size: 17px;
  }
}
@include respond-to('sm') {
  .side-panel__switch {
    height: 60px;
    padding: 0 16px;
    .side-panel__label {
      font-size: 19px;
    }
  }
}

.btn {
  margin: 20px 14px 0;
}
</style>
